<template>
    <div class="p-5 text-center">
        <x-circle-icon size="4x" class="text-danger mb-3"></x-circle-icon>
        <h2>Ooops</h2>
        <p>Si è verificato un errore con il pagamento. Si prega di riprovare</p>
        <a href="/pricing" class="btn btn-primary">Ripeti l'acquisto</a>
        &nbsp;&nbsp;&nbsp;<a href="#" @click="this.$router.push('/')" class="btn btn-outline-primary">Vai alla home</a>
    </div>
</template>

<script>
import { XCircleIcon } from 'vue-feather-icons';
export default {
    components: {
        XCircleIcon
    }
}
</script>